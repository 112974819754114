<template>
    <section :id="sectionId" :class="`py-20 ${bgColor}`">
        <div class="container mx-auto px-4">
            <div class="flex flex-col lg:flex-row items-start lg:items-center gap-12">
                <div class="lg:w-1/2">
                    <div class="inline-flex items-center justify-center w-16 h-16 rounded-full bg-emerald-500/10 mb-6">
                        <component :is="icon" class="h-8 w-8 text-emerald-500" />
                    </div>
                    <h2 class="text-4xl font-bold mb-6">
                        <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
                            {{ title }}
                        </span>
                    </h2>
                    <p class="text-xl text-zinc-400 mb-8">{{ description }}</p>
                    <ul class="space-y-4">
                        <li v-for="(feature, index) in features" :key="index" class="flex items-center">
                            <Check class="h-5 w-5 text-emerald-500 mr-2" />
                            <span>{{ feature }}</span>
                        </li>
                    </ul>
                </div>
                <div class="lg:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-6">
                    <div v-for="(card, index) in cards" :key="index"
                        class="bg-zinc-800/50 border border-zinc-700 backdrop-blur-xl hover:bg-zinc-800/80 transition-colors rounded-xl p-6">
                        <div class="flex flex-col items-center text-center">
                            <div class="rounded-full bg-emerald-500/10 p-3 mb-4">
                                <component :is="card.icon" class="h-6 w-6 text-emerald-500" />
                            </div>
                            <h3 class="font-semibold mb-2 text-zinc-100">{{ card.title }}</h3>
                            <p class="text-sm text-zinc-400">{{ card.description }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { Check } from 'lucide-vue-next'
import { watch, nextTick } from 'vue'

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    description: {
        type: String,
        required: true
    },
    icon: {
        type: Object,
        required: true
    },
    bgColor: {
        type: String,
        default: 'bg-zinc-900'
    },
    features: {
        type: Array,
        required: true
    },
    cards: {
        type: Array,
        required: true
    },
    sectionId: {
        type: String,
        required: true
    }
})

// Force component to re-render when cards prop changes
watch(() => props.cards, () => {
    nextTick(() => {
        // Component will re-render on next tick
    })
}, { deep: true })
</script>