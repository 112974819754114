<template>
  <div>
    <!-- Hero Section -->
    <section class="relative overflow-hidden py-20 sm:py-32">
      <div class="container mx-auto px-4 relative z-10">
        <div class="max-w-4xl mx-auto text-center">
          <div class="inline-flex items-center rounded-full border border-zinc-800 bg-zinc-900/50 px-3 py-1 text-sm backdrop-blur-xl">
            <span class="flex h-2 w-2 rounded-full bg-emerald-500 mr-2"></span>
            {{ t('contact.hero.tagline') }}
          </div>
          <h1 class="mt-8 text-5xl md:text-7xl font-bold tracking-tight">
            {{ t('contact.hero.title') }}
            <span class="block text-emerald-500">{{ t('contact.hero.subtitle') }}</span>
          </h1>
          <p class="mt-6 text-xl text-zinc-400 max-w-2xl mx-auto">
            {{ t('contact.hero.description') }}
          </p>
        </div>
      </div>
      <div class="absolute inset-0 bg-[radial-gradient(circle_at_top,rgba(16,185,129,0.1),transparent)]"></div>
    </section>

    <ContactSection />
  </div>
</template>

<script setup>
import ContactSection from '../components/ContactSection.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

defineOptions({
  name: 'ContactPage'
})
</script> 