<template>
  <section id="use-cases" class="relative overflow-hidden bg-zinc-900 py-20 min-h-screen flex items-center justify-center" ref="sectionRef">
    <div class="container mx-auto px-4 relative z-10">
      <div class="text-center mb-16">
        <h2 class="text-4xl font-bold text-center mb-16">
          <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
            {{ t('useCases.title') }}
          </span>
        </h2>
        <p class="text-xl text-zinc-300 max-w-3xl mx-auto">
          {{ t('useCases.subtitle') }}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-12 items-center mb-16">
        <div class="bg-zinc-900/50 backdrop-blur-xl border border-zinc-800 rounded-xl p-8 flex flex-col items-center justify-center">
          <img
            src="../assets/img/tisalabs_logo.png"
            alt="TisaLabs Logo"
            class="mb-6 w-[200px] h-[100px] object-contain"
          />
          <h3 class="text-2xl font-bold text-zinc-100 mb-4">TisaLabs</h3>
          <p class="text-zinc-400 text-center">
            {{ t('useCases.tisalabs.description') }}
          </p>
        </div>

        <div>
          <h3 class="text-2xl font-bold text-zinc-100 mb-6">{{ t('useCases.keyInnovations') }}</h3>
          <ul class="space-y-6">
            <li class="flex items-start">
              <MessageSquare class="h-6 w-6 text-emerald-500 mr-3 mt-1 flex-shrink-0" />
              <div>
                <span class="text-zinc-100 font-medium">{{ t('useCases.tisalabs.innovations.chatbot.title') }}</span>
                <p class="text-zinc-400 text-sm mt-1">
                  {{ t('useCases.tisalabs.innovations.chatbot.description') }}
                </p>
              </div>
            </li>
            <li class="flex items-start">
              <Search class="h-6 w-6 text-emerald-500 mr-3 mt-1 flex-shrink-0" />
              <div>
                <span class="text-zinc-100 font-medium">{{ t('useCases.tisalabs.innovations.seo.title') }}</span>
                <p class="text-zinc-400 text-sm mt-1">
                  {{ t('useCases.tisalabs.innovations.seo.description') }}
                </p>
              </div>
            </li>
            <li class="flex items-start">
              <Shield class="h-6 w-6 text-emerald-500 mr-3 mt-1 flex-shrink-0" />
              <div>
                <span class="text-zinc-100 font-medium">{{ t('useCases.tisalabs.innovations.content.title') }}</span>
                <p class="text-zinc-400 text-sm mt-1">
                  {{ t('useCases.tisalabs.innovations.content.description') }}
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="text-center">
        <p class="text-xl text-zinc-300 mb-8">
          {{ t('useCases.readyToTransform') }}
        </p>
        <router-link
          to="/contact"
          class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-zinc-900 bg-emerald-500 hover:bg-emerald-600 transition-colors duration-300"
        >
          {{ t('useCases.cta') }}
          <Zap class="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { Zap, MessageSquare, Search, Shield } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const sectionRef = ref(null)
</script>
