<template>
  <div class="min-h-screen bg-zinc-950 text-zinc-100">
    <NavBar />
    <router-view v-slot="{ Component }">
      <transition name="page" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
    <FooterSection />
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { useI18n } from 'vue-i18n'
import NavBar from './components/NavBar.vue'
import FooterSection from './components/FooterSection.vue'

const { t, locale } = useI18n()

// Update HTML lang attribute and meta tags when language changes
watch(locale, (newLocale) => {
  document.documentElement.lang = newLocale
  // Update title
  document.title = t('meta.title')
  // Update meta description
  document.querySelector('meta[name="description"]').setAttribute('content', t('meta.description'))
}, { immediate: true })
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s ease;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}

/* Add intersection observer animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

section {
  opacity: 0;
  animation: fadeInUp 0.6s ease forwards;
}

section.visible {
  opacity: 1;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

/* Add navigation transitions */
@keyframes fadeSlideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.nav-scroll-trigger {
  animation: fadeSlideDown 0.5s ease forwards;
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: auto;
}

/* Add intersection observer animations */
.section-enter {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease, transform 0.6s ease;
}

.section-enter-active {
  opacity: 1;
  transform: translateY(0);
}

</style>
