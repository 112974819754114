<template>
  <section id="benefits" class="relative overflow-hidden bg-zinc-950 py-32 min-h-screen flex items-center justify-center" ref="sectionRef">
    <div class="absolute top-0 left-0 right-0 h-48 bg-gradient-to-b from-zinc-950 via-zinc-950/80 to-transparent z-[2]"></div>
    <div class="absolute inset-0 bg-[radial-gradient(circle_at_top,rgba(16,185,129,0.1),transparent)] z-[1]" />
    <div class="z-[3]">
      <ParticleBackground />
    </div>
    
    <div class="container mx-auto px-4 relative z-[20]">
      <div class="text-center mb-24">
        <h2 class="text-4xl font-bold text-center mb-16">
          <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
            {{ t('benefits.title') }}
          </span>
        </h2>
        <p class="text-xl text-zinc-300 max-w-3xl mx-auto">
          {{ t('benefits.subtitle') }}
        </p>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
        <div v-for="(stat, index) in stats" :key="index" class="flex flex-col items-center">
          <CircularProgress :value="stat.value" :icon="stat.icon" />
          <div class="mt-4 text-center">
            <div class="text-4xl font-bold text-emerald-500 mb-2">{{ stat.value }}</div>
            <p class="text-lg text-zinc-200 mb-2 font-semibold">{{ stat.label }}</p>
            <p class="text-sm text-zinc-400">{{ stat.description }}</p>
          </div>
        </div>
      </div>

      <div class="mt-16 text-center">
        <router-link
          to="/contact"
          class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-zinc-900 bg-emerald-500 hover:bg-emerald-600 transition-colors duration-300"
        >
          {{ t('benefits.cta') }}
          <Zap class="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
        </router-link>
      </div>
    </div>

    <div class="absolute bottom-0 left-0 right-0 h-48 bg-gradient-to-t from-zinc-950 via-zinc-950/80 to-transparent z-[2]"></div>
  </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { Zap, Clock, Target, Wallet } from 'lucide-vue-next'
import CircularProgress from './CircularProgress.vue'
import ParticleBackground from './ParticleBackground.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const stats = computed(() => [
  {
    icon: Zap,
    value: t('benefits.stats.speed.value'),
    label: t('benefits.stats.speed.label'),
    description: t('benefits.stats.speed.description')
  },
  {
    icon: Clock,
    value: t('benefits.stats.availability.value'),
    label: t('benefits.stats.availability.label'),
    description: t('benefits.stats.availability.description')
  },
  {
    icon: Target,
    value: t('benefits.stats.accuracy.value'),
    label: t('benefits.stats.accuracy.label'),
    description: t('benefits.stats.accuracy.description')
  },
  {
    icon: Wallet,
    value: t('benefits.stats.cost.value'),
    label: t('benefits.stats.cost.label'),
    description: t('benefits.stats.cost.description')
  }
])

const sectionRef = ref(null)

onMounted(() => {
  // Add any additional initialization if needed
})
</script>
