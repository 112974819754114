<template>
  <div v-if="isI18nReady">
    <ServiceSection
      sectionId="chatbots"
      :title="t('solutions.chatbots.title')"
      :description="t('solutions.chatbots.description')"
      :icon="Bot"
      :features="chatbotFeatures"
      :cards="chatbotCards"
    />

    <ServiceSection
      sectionId="voice-agents"
      :title="t('solutions.voiceAgents.title')"
      :description="t('solutions.voiceAgents.description')"
      :icon="Mic"
      bgColor="bg-zinc-950"
      :features="voiceFeatures"
      :cards="voiceCards"
    />

    <ServiceSection
      sectionId="automations"
      :title="t('solutions.automation.title')"
      :description="t('solutions.automation.description')"
      :icon="Workflow"
      :features="automationFeatures"
      :cards="automationCards"
    />

    <ServiceSection
      sectionId="marketing"
      :title="t('solutions.marketing.title')"
      :description="t('solutions.marketing.description')"
      :icon="Target"
      bgColor="bg-zinc-950"
      :features="marketingFeatures"
      :cards="marketingCards"
    />

    <ServiceSection
      sectionId="lead-generation"
      :title="t('solutions.leadGen.title')"
      :description="t('solutions.leadGen.description')"
      :icon="UserPlus"
      :features="leadGenFeatures"
      :cards="leadGenCards"
    />
  </div>
</template>

<script setup>
import ServiceSection from './ServiceSection.vue'
import { 
  Bot, Mic, HeadphonesIcon, PhoneCall, Workflow, Mail, 
  Calendar, Target, MessageCircle, UserPlus, Sprout, 
  GitBranch, ShoppingCart, BookOpen, Presentation 
} from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
import { computed, ref, onMounted } from 'vue'

const { t, tm } = useI18n()
const isI18nReady = ref(false)

onMounted(() => {
  // Verify i18n is loaded
  console.log('Current locale:', t('solutions.leadGen.features'))
  isI18nReady.value = true
})

const chatbotCards = computed(() => [
  { 
    icon: HeadphonesIcon, 
    title: t('solutions.cards.chatbot.customerService.title'),
    description: t('solutions.cards.chatbot.customerService.description')
  },
  { 
    icon: ShoppingCart, 
    title: t('solutions.cards.chatbot.salesAssistant.title'),
    description: t('solutions.cards.chatbot.salesAssistant.description')
  },
  { 
    icon: BookOpen, 
    title: t('solutions.cards.chatbot.informational.title'),
    description: t('solutions.cards.chatbot.informational.description')
  },
  { 
    icon: Presentation, 
    title: t('solutions.cards.chatbot.tutor.title'),
    description: t('solutions.cards.chatbot.tutor.description')
  }
])

const voiceCards = computed(() => [
  { 
    icon: PhoneCall, 
    title: t('solutions.cards.voice.inboundOutbound.title'),
    description: t('solutions.cards.voice.inboundOutbound.description')
  },
  { 
    icon: Mic, 
    title: t('solutions.cards.voice.commands.title'),
    description: t('solutions.cards.voice.commands.description')
  },
  { 
    icon: Bot, 
    title: t('solutions.cards.voice.assistant.title'),
    description: t('solutions.cards.voice.assistant.description')
  }
])

const automationCards = computed(() => [
  { 
    icon: Workflow, 
    title: t('solutions.cards.automation.workflow.title'),
    description: t('solutions.cards.automation.workflow.description')
  },
  { 
    icon: Mail, 
    title: t('solutions.cards.automation.email.title'),
    description: t('solutions.cards.automation.email.description')
  },
  { 
    icon: Calendar, 
    title: t('solutions.cards.automation.scheduling.title'),
    description: t('solutions.cards.automation.scheduling.description')
  }
])

const marketingCards = computed(() => [
  { 
    icon: Target, 
    title: t('solutions.cards.marketing.advertising.title'),
    description: t('solutions.cards.marketing.advertising.description')
  },
  { 
    icon: MessageCircle, 
    title: t('solutions.cards.marketing.socialMedia.title'),
    description: t('solutions.cards.marketing.socialMedia.description')
  },
  { 
    icon: Mail, 
    title: t('solutions.cards.marketing.email.title'),
    description: t('solutions.cards.marketing.email.description')
  }
])

const leadGenCards = computed(() => [
  { 
    icon: UserPlus, 
    title: t('solutions.cards.leadGen.identification.title'),
    description: t('solutions.cards.leadGen.identification.description')
  },
  { 
    icon: Sprout, 
    title: t('solutions.cards.leadGen.nurturing.title'),
    description: t('solutions.cards.leadGen.nurturing.description')
  },
  { 
    icon: GitBranch, 
    title: t('solutions.cards.leadGen.optimization.title'),
    description: t('solutions.cards.leadGen.optimization.description')
  }
])

// Define computed properties for features consistently
const chatbotFeatures = computed(() => {
  const features = tm('solutions.chatbots.features')
  return Array.isArray(features) ? features : []
})

const voiceFeatures = computed(() => {
  const features = tm('solutions.voiceAgents.features')
  return Array.isArray(features) ? features : []
})

const automationFeatures = computed(() => {
  const features = tm('solutions.automation.features')
  return Array.isArray(features) ? features : []
})

const marketingFeatures = computed(() => {
  const features = tm('solutions.marketing.features')
  return Array.isArray(features) ? features : []
})

const leadGenFeatures = computed(() => {
  const features = tm('solutions.leadGen.features')
  return Array.isArray(features) ? features : []
})

console.log('Features:', chatbotFeatures.value);
</script> 