<template>
  <section class="py-20 relative">
    <div class="container mx-auto px-4">
      <h2 class="text-4xl font-bold text-center mb-16">
        <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
          {{ t('faq.title') }}
        </span>
      </h2>
      <div class="max-w-3xl mx-auto">
        <div v-for="(faq, index) in faqs" :key="index" class="mb-4">
          <div 
            @click="toggleFaq(index)"
            class="flex justify-between items-center p-4 cursor-pointer bg-zinc-900/50 hover:bg-zinc-900/80 rounded-t-xl"
            :class="{ 'rounded-b-xl': activeIndex !== index }"
          >
            <h3 class="text-lg font-medium">{{ faq.question }}</h3>
            <ChevronDown 
              class="h-5 w-5 transition-transform"
              :class="{ 'transform rotate-180': activeIndex === index }"
            />
          </div>
          <div 
            v-show="activeIndex === index"
            class="p-4 bg-zinc-900/30 rounded-b-xl border-t border-zinc-800"
          >
            <p class="text-zinc-400">{{ faq.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { ChevronDown } from 'lucide-vue-next'

const { t, tm } = useI18n()
const activeIndex = ref(null)

const faqs = computed(() => {
  const questions = tm('faq.questions')
  return Array.isArray(questions) ? questions : []
})

const toggleFaq = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index
}
</script>
