<template>
    <section id="contact">
        <!-- Cal.com Embed Section -->
        <div style="margin: 20px auto; max-width: 1200px;">
            <div style="width: auto; height: auto;" id="my-cal-inline"></div>
        </div>

        <div class="container mx-auto px-4 mt-16">
            <div class="max-w-2xl mx-auto">
                <div class="grid grid-cols-1 gap-8 mb-16">
                    <!-- Contact Info Cards -->
                    <div class="rounded-2xl p-6 bg-zinc-800/30 backdrop-blur-xl border border-zinc-700/50 hover:bg-zinc-800/50 transition-all duration-300">
                        <div class="flex items-center space-x-4">
                            <div class="p-3 rounded-xl bg-emerald-500/10">
                                <Phone class="h-5 w-5 text-emerald-500" />
                            </div>
                            <div>
                                <p class="text-sm text-zinc-400 mb-1">{{ t('contact.info.phone.label') }}</p>
                                <p class="font-medium text-zinc-200">{{ t('contact.info.phone.value') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="rounded-2xl p-6 bg-zinc-800/30 backdrop-blur-xl border border-zinc-700/50 hover:bg-zinc-800/50 transition-all duration-300">
                        <div class="flex items-center space-x-4">
                            <div class="p-3 rounded-xl bg-emerald-500/10">
                                <Mail class="h-5 w-5 text-emerald-500" />
                            </div>
                            <div>
                                <p class="text-sm text-zinc-400 mb-1">{{ t('contact.info.email.label') }}</p>
                                <a :href="'mailto:' + emailAddress" class="font-medium text-zinc-200 hover:text-emerald-500 transition-colors">{{ emailAddress }}</a>
                            </div>
                        </div>
                    </div>

                    <div class="rounded-2xl p-6 bg-zinc-800/30 backdrop-blur-xl border border-zinc-700/50 hover:bg-zinc-800/50 transition-all duration-300">
                        <div class="flex items-center space-x-4">
                            <div class="p-3 rounded-xl bg-emerald-500/10">
                                <MapPin class="h-5 w-5 text-emerald-500" />
                            </div>
                            <div>
                                <p class="text-sm text-zinc-400 mb-1">{{ t('contact.info.location.label') }}</p>
                                <p class="font-medium text-zinc-200">{{ t('contact.info.location.value') }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Contact Form -->
                <div class="rounded-2xl p-8 bg-zinc-800/30 backdrop-blur-xl border border-zinc-700/50 mt-24">
                    <h3 class="text-2xl font-bold mb-6">{{ t('contact.form.title') }}</h3>
                    <p class="text-zinc-400 text-sm mb-8 text-left">
                        {{ t('contact.form.description') }}
                    </p>
                    <form @submit.prevent="handleSubmit" class="space-y-6">
                        <div>
                            <label class="block text-sm font-medium mb-2">{{ t('contact.form.fields.name') }}</label>
                            <input v-model="formData.name" type="text"
                                class="w-full px-4 py-3 bg-zinc-800 border border-zinc-700 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                                required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium mb-2">{{ t('contact.form.fields.email') }}</label>
                            <input v-model="formData.email" type="email"
                                class="w-full px-4 py-3 bg-zinc-800 border border-zinc-700 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                                required />
                        </div>
                        <div>
                            <label class="block text-sm font-medium mb-2">{{ t('contact.form.fields.message') }}</label>
                            <textarea v-model="formData.message" rows="4"
                                class="w-full px-4 py-3 bg-zinc-800 border border-zinc-700 rounded-md focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:border-transparent"
                                required></textarea>
                        </div>
                        <div v-if="submitStatus" class="text-sm rounded-md p-3 mb-4" :class="{
                            'bg-emerald-500/10 text-emerald-500': submitStatus === 'success',
                            'bg-red-500/10 text-red-500': submitStatus === 'error'
                        }">
                            <p v-if="submitStatus === 'success'">{{ t('contact.form.success') }}</p>
                            <p v-if="submitStatus === 'error'">{{ t('contact.form.error') }}</p>
                        </div>
                        <div class="flex justify-end">
                            <button type="submit"
                                class="px-6 py-3 bg-emerald-500 hover:bg-emerald-600 text-zinc-950 rounded-md transition-colors flex items-center disabled:opacity-50 disabled:cursor-not-allowed"
                                :disabled="isSubmitting">
                                <span>{{ isSubmitting ? t('contact.form.sending') : t('contact.form.submit') }}</span>
                                <Send v-if="!isSubmitting" class="ml-2 h-4 w-4" />
                                <Loader2 v-else class="ml-2 h-4 w-4 animate-spin" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Add FAQ Section -->
        <div class="container mx-auto px-4 mt-32 mb-32">
            <div class="max-w-3xl mx-auto">
                <h2 class="text-4xl font-bold text-center mb-16">
                    <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
                        Frequently Asked Questions
                    </span>
                </h2>
                <div v-for="(faq, index) in faqs" :key="index" class="mb-4">
                    <div 
                        @click="toggleFaq(index)"
                        class="flex justify-between items-center p-4 cursor-pointer bg-zinc-900/50 hover:bg-zinc-900/80 rounded-t-xl"
                        :class="{ 'rounded-b-xl': activeIndex !== index }"
                    >
                        <h3 class="text-lg font-medium">{{ faq.question }}</h3>
                        <ChevronDown 
                            class="h-5 w-5 transition-transform"
                            :class="{ 'transform rotate-180': activeIndex === index }"
                        />
                    </div>
                    <div 
                        v-show="activeIndex === index"
                        class="p-4 bg-zinc-900/30 rounded-b-xl border-t border-zinc-800"
                    >
                        <p class="text-zinc-400">{{ faq.answer }}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { Send, Loader2, ChevronDown, Phone, Mail, MapPin } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
const { t, tm } = useI18n()

const isSubmitting = ref(false)
const submitStatus = ref(null)

const formData = ref({
    name: '',
    email: '',
    message: ''
})

const activeIndex = ref(null)

const toggleFaq = (index) => {
  activeIndex.value = activeIndex.value === index ? null : index
}

const faqs = computed(() => {
  const questions = tm('faq.questions')
  return Array.isArray(questions) ? questions : []
})

// Add Cal.com initialization
onMounted(() => {
    /* eslint-disable */
    (function (C, A, L) {
        let p = function (a, ar) { a.q.push(ar); }
        let d = C.document
        C.Cal = C.Cal || function () {
            let cal = C.Cal
            let ar = arguments
            if (!cal.loaded) {
                cal.ns = {}
                cal.q = cal.q || []
                d.head.appendChild(d.createElement("script")).src = A
                cal.loaded = true
            }
            if (ar[0] === L) {
                const api = function () { p(api, arguments) }
                const namespace = ar[1]
                api.q = api.q || []
                if (typeof namespace === "string") {
                    cal.ns[namespace] = cal.ns[namespace] || api
                    p(cal.ns[namespace], ar)
                    p(cal, ["initNamespace", namespace])
                } else p(cal, ar)
                return
            }
            p(cal, ar)
        }
    })(window, "https://app.cal.com/embed/embed.js", "init")

    Cal("init", "15min", {origin:"https://cal.com"})

    Cal.ns["15min"]("inline", {
        elementOrSelector: "#my-cal-inline",
        config: {
            "layout": "month_view",
            "theme": "dark"
        },
        calLink: "ciobai/15min",
    })

    Cal.ns["15min"]("ui", {
        "theme": "dark",
        "styles": {
            "branding": {
                "brandColor": "#3fec08"
            }
        },
        "hideEventTypeDetails": false,
        "layout": "month_view"
    })
    /* eslint-enable */
})

const handleSubmit = async () => {
    try {
        isSubmitting.value = true
        const response = await fetch('https://formspree.io/f/xgvezoqw', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData.value)
        })

        if (response.ok) {
            submitStatus.value = 'success'
            formData.value = {
                name: '',
                email: '',
                message: ''
            }
        } else {
            throw new Error('Form submission failed')
        }
    } catch (error) {
        submitStatus.value = 'error'
        console.error('Form submission error:', error)
    } finally {
        isSubmitting.value = false
        setTimeout(() => {
            submitStatus.value = null
        }, 3000)
    }
}

const emailAddress = computed(() => 'alex@ciobai.com')
</script>

<style scoped>
section {
    min-height: 100vh;
    width: 100%;
}
</style>