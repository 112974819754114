<template>
  <section id="process" class="py-20 relative">
    <div class="container mx-auto px-4">
      <h2 class="text-4xl font-bold text-center mb-16">
        <span class="bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
          {{ t('process.title') }}
        </span>
      </h2>
      <div class="max-w-3xl mx-auto relative">
        <!-- Vertical line -->
        <div class="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-zinc-800"></div>

        <!-- Timeline items -->
        <div class="space-y-16">
          <div v-for="(step, index) in processSteps" :key="index" class="relative flex items-center"
            :class="{ 'flex-row-reverse': index % 2 !== 0 }">
            <!-- Content -->
            <div class="w-1/2" :class="{ 'pr-12': index % 2 === 0, 'pl-12': index % 2 !== 0 }">
              <div
                class="bg-zinc-900/50 border-zinc-800 backdrop-blur-xl hover:bg-zinc-900/80 transition-colors p-6 rounded-xl"
                :class="{ 'text-right': index % 2 === 0 }">
                <div class="flex flex-col items-center">
                  <div class="rounded-full bg-emerald-500/10 p-3 mb-4">
                    <component :is="step.icon" class="h-6 w-6 text-emerald-500" />
                  </div>
                  <h3 class="font-semibold mb-2 text-zinc-100">{{ step.title }}</h3>
                  <p class="text-sm text-zinc-400">{{ step.description }}</p>
                </div>
              </div>
            </div>

            <!-- Timeline dot -->
            <div class="absolute left-1/2 transform -translate-x-1/2 flex items-center justify-center">
              <div
                class="w-8 h-8 bg-emerald-500 rounded-full flex items-center justify-center text-zinc-900 font-bold text-sm">
                {{ index + 1 }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-20 text-center">
        <router-link to="/contact">
          <button
            class="h-12 px-8 bg-emerald-500 hover:bg-emerald-600 text-zinc-950 rounded-md flex items-center mx-auto">
            Start Your Free Prototype
            <Gift class="ml-2 h-4 w-4" />
          </button>
        </router-link>

      </div>
    </div>
  </section>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Lightbulb, Rocket, Workflow, Target, Gift } from 'lucide-vue-next'

const { t } = useI18n()

const processSteps = computed(() => [
  {
    icon: Lightbulb,
    title: t('process.steps.discovery.title'),
    description: t('process.steps.discovery.description')
  },
  {
    icon: Rocket,
    title: t('process.steps.prototype.title'),
    description: t('process.steps.prototype.description')
  },
  {
    icon: Workflow,
    title: t('process.steps.development.title'),
    description: t('process.steps.development.description')
  },
  {
    icon: Target,
    title: t('process.steps.deployment.title'),
    description: t('process.steps.deployment.description')
  }
])
</script>
