import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/Home.vue'
import ContactPage from '../views/Contact.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    
    return new Promise((resolve) => {
      if (to.hash) {
        // Wait for the component to be rendered
        setTimeout(() => {
          resolve({
            el: to.hash,
            behavior: 'smooth',
            top: 64 // navbar height
          })
        }, 500) // Add a small delay to ensure component is mounted
      } else {
        resolve({ top: 0 })
      }
    })
  }
})

export default router 