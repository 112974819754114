<template>
  <footer class="border-t border-zinc-800 py-12">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8 mb-8">
        <div>
          <div class="flex items-center mb-4">
            <Bot class="h-6 w-6 text-emerald-500" />
            <span class="ml-2 font-bold bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
              Ciobai
            </span>
          </div>
          <p class="text-zinc-400 text-sm">
            {{ t('footer.company.description') }}
          </p>
          <div class="mt-4 space-y-2">
            <p class="text-sm text-zinc-400">
              <span class="font-semibold">{{ t('footer.company.contact.phone') }}:</span> +41 78 422 70 91
            </p>
            <p class="text-sm text-zinc-400">
              <span class="font-semibold">{{ t('footer.company.contact.email') }}:</span> 
              <a :href="'mailto:' + emailAddress" class="hover:text-zinc-100 transition-colors">
                {{ emailAddress }}
              </a>
            </p>
            <p class="text-sm text-zinc-400">
              <span class="font-semibold">{{ t('footer.company.contact.location') }}:</span> Bellinzona, Ticino, Switzerland
            </p>
          </div>
        </div>
        
        <div v-for="(section, index) in footerSections" :key="index">
          <h3 class="font-semibold mb-4">{{ section.title }}</h3>
          <ul class="space-y-2">
            <li v-for="(link, linkIndex) in section.links" :key="linkIndex">
              <a 
                :href="link.href" 
                class="text-sm text-zinc-400 hover:text-zinc-100 transition-colors"
              >
                {{ link.text }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      
      <div class="border-t border-zinc-800 pt-8 mt-8 flex flex-col md:flex-row justify-between items-center">
        <p class="text-sm text-zinc-400 mb-4 md:mb-0">
          {{ t('footer.copyright', { year: new Date().getFullYear() }) }}
        </p>
        <div class="flex space-x-6">
          <a v-for="(social, index) in socialLinks" 
             :key="index"
             :href="social.href"
             class="text-zinc-400 hover:text-zinc-100 transition-colors"
          >
            <component :is="social.icon" class="h-5 w-5" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import { Bot } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const { t } = useI18n()

const footerSections = computed(() => [
  {
    title: t('nav.solutions'),
    links: [
      { text: t('solutions.links.chatbots'), href: '#chatbots' },
      { text: t('solutions.links.voiceAgents'), href: '#voice-agents' },
      { text: t('solutions.links.processAutomation'), href: '#automations' },
      { text: t('solutions.links.leadGeneration'), href: '#lead-generation' }
    ]
  },
  {
    title: t('nav.company'),
    links: [
      { text: t('nav.contact'), href: '/contact' }
    ]
  },
  {
    //title: t('legal.title'),
    links: [
      // { text: t('legal.privacy'), href: '#' },
      // { text: t('legal.terms'), href: '#' },
      // { text: t('legal.cookies'), href: '#' }
    ]
  }
])

/*
const socialLinks = [
  { icon: Linkedin, href: '#' },
  { icon: Twitter, href: '#' },
  { icon: Github, href: '#' }
]
  */

const emailAddress = computed(() => 'alex@ciobai.com')
</script>
