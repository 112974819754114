<template>
  <div class="relative">
    <button 
      @click="isOpen = !isOpen"
      class="flex items-center space-x-2 text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors"
    >
      <Globe class="h-4 w-4" />
      <span>{{ currentLanguage }}</span>
      <ChevronDown class="h-4 w-4 transition-transform" :class="{ 'rotate-180': isOpen }" />
    </button>
    
    <div 
      v-if="isOpen"
      class="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-zinc-900 ring-1 ring-black ring-opacity-5"
    >
      <div class="py-1">
        <button
          v-for="lang in languages"
          :key="lang.code"
          @click="changeLanguage(lang.code)"
          class="block w-full text-left px-4 py-2 text-sm text-zinc-400 hover:text-zinc-100 hover:bg-zinc-800"
        >
          {{ lang.name }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { Globe, ChevronDown } from 'lucide-vue-next'

const { locale } = useI18n()
const isOpen = ref(false)

const languages = [
  { code: 'en', name: 'English' },
  { code: 'it', name: 'Italiano' }
]

const currentLanguage = computed(() => {
  return languages.find(lang => lang.code === locale.value)?.name
})

const changeLanguage = (langCode) => {
  locale.value = langCode
  isOpen.value = false
  localStorage.setItem('language', langCode)
}
</script> 