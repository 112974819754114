<template>
  <section class="relative py-20">
    <div class="container mx-auto px-4 relative z-10">
      <div class="bg-zinc-900/50 backdrop-blur-xl border border-zinc-800 rounded-2xl p-8 md:p-12 text-center">
        <h2 class="text-3xl md:text-4xl font-bold mb-6">{{ t('cta.ready') }}</h2>
        <p class="text-lg mb-8 text-zinc-400 max-w-2xl mx-auto">
          {{ t('cta.description') }}
        </p>
        <router-link to="/contact" class="h-12 px-8 bg-emerald-500 hover:bg-emerald-600 text-zinc-950 rounded-md inline-flex items-center">
          {{ t('cta.button') }}
          <Gift class="ml-2 h-4 w-4" />
        </router-link>
      </div>
    </div>
  </section>
</template>

<script setup>
import { Gift } from 'lucide-vue-next'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
