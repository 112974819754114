<template>
  <div ref="containerRef" class="absolute inset-0 overflow-hidden">
    <div
      v-for="(particle, index) in particles"
      :key="index"
      class="particle absolute w-1 h-1 bg-emerald-500 rounded-full"
      :style="{
        left: `${particle.x}px`,
        top: `${particle.y}px`,
        '--tx': `${particle.tx}px`,
        '--ty': `${particle.ty}px`,
        animationDuration: `${particle.duration}s`
      }"
    ></div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const containerRef = ref(null)
const particles = ref([])

const randomOffset = () => Math.random() * 100 - 50

onMounted(() => {
  if (containerRef.value) {
    const { width, height } = containerRef.value.getBoundingClientRect()
    const particleCount = Math.floor((width * height) / 10000)
    
    particles.value = Array.from({ length: particleCount }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      tx: randomOffset(),
      ty: randomOffset(),
      duration: 5 + Math.random() * 5
    }))
  }
})
</script>

<style scoped>
.particle {
  animation: particle infinite alternate ease-in-out;
  opacity: 0.2;
}

@keyframes particle {
  0% {
    transform: translate(0, 0) scale(0.5);
    opacity: 0.2;
  }
  50% {
    transform: translate(var(--tx), var(--ty)) scale(1);
    opacity: 0.5;
  }
  100% {
    transform: translate(calc(var(--tx) * 2), calc(var(--ty) * 2)) scale(0.5);
    opacity: 0.2;
  }
}
</style> 