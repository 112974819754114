import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

// Import translations
import en from './i18n/locales/en.json'
import it from './i18n/locales/it.json'

// Get stored language or default to 'en'
const storedLang = localStorage.getItem('language') || 'en'

// Create i18n instance
const i18n = createI18n({
  legacy: false, // Set to false to use Composition API
  locale: storedLang,
  fallbackLocale: 'en',
  messages: {
    en,
    it
  }
})

const app = createApp(App)
app.use(router)
app.use(i18n)
app.mount('#app')
