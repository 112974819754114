<template>
  <nav class="border-b border-zinc-800 bg-zinc-950/50 backdrop-blur-xl sticky top-0 z-50">
    <div class="px-4">
      <div class="flex h-20 min-[840px]:h-16 items-center justify-between max-w-[1400px] mx-auto">
        <div class="flex max-[360px]:flex-col max-[360px]:items-start items-center">
          <router-link to="/" class="flex items-center" @click="closeMenus">
            <Bot class="h-6 w-6 sm:h-8 sm:w-8 text-emerald-500" />
            <span class="ml-2 text-lg sm:text-xl font-bold bg-gradient-to-r from-emerald-500 to-emerald-400 bg-clip-text text-transparent">
              Ciobai
            </span>
          </router-link>
          <div class="ml-2 max-[360px]:ml-0 max-[360px]:mt-1 rounded-full bg-emerald-900/50 px-3 py-1 text-xs font-medium text-emerald-500 flex items-center">
            <MapPin class="h-3 w-3 mr-1.5" />
            {{ t('nav.location') }}
          </div>
        </div>

        <div class="flex items-center">
          <div class="hidden min-[930px]:flex items-center space-x-4 lg:space-x-8">
            <router-link to="/#benefits" class="text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors" @click="isMobileMenuOpen = false">
              {{ t('nav.benefits') }}
            </router-link>
            
            <!-- Solutions Dropdown -->
            <div class="relative">
              <button 
                @click="isSolutionsOpen = !isSolutionsOpen"
                class="text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors flex items-center"
              >
                {{ t('nav.solutions') }}
                <ChevronDown class="h-4 w-4 ml-1 transition-transform" :class="{ 'rotate-180': isSolutionsOpen }" />
              </button>
              <div 
                v-if="isSolutionsOpen"
                class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-zinc-900 ring-1 ring-black ring-opacity-5 transition-all duration-200 transform origin-top-left"
              >
                <div class="py-1">
                  <router-link v-for="solution in solutionLinks" 
                     :key="solution.href" 
                     :to="`/${solution.href}`"
                     class="block px-4 py-2 text-sm text-zinc-400 hover:text-zinc-100 hover:bg-zinc-800"
                     @click="closeMenus"
                  >
                    {{ solution.text }}
                  </router-link>
                </div>
              </div>
            </div>

            <router-link to="/#process" class="text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors" @click="isMobileMenuOpen = false">
              {{ t('nav.process') }}
            </router-link>
            <router-link to="/#use-cases" class="text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors" @click="isMobileMenuOpen = false">
              {{ t('nav.useCases') }}
            </router-link>
            <router-link to="/contact" class="text-sm font-medium text-zinc-400 hover:text-zinc-100 transition-colors">
              {{ t('nav.contact') }}
            </router-link>
            <LanguageSwitcher />
            <router-link to="/contact" class="whitespace-nowrap px-3 lg:px-4 py-2 bg-emerald-500 hover:bg-emerald-600 text-zinc-950 rounded-md transition-colors text-sm">
              {{ t('nav.getPrototype') }}
            </router-link>
          </div>

          <!-- Mobile menu button -->
          <button 
            @click="isMobileMenuOpen = !isMobileMenuOpen"
            class="min-[930px]:hidden p-2 text-zinc-400 hover:text-zinc-100"
          >
            <Menu v-if="!isMobileMenuOpen" class="h-6 w-6" />
            <X v-else class="h-6 w-6" />
          </button>
        </div>
      </div>

      <!-- Mobile menu -->
      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="transform -translate-y-2 opacity-0"
        enter-to-class="transform translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="transform translate-y-0 opacity-100"
        leave-to-class="transform -translate-y-2 opacity-0"
      >
        <div v-if="isMobileMenuOpen" class="min-[930px]:hidden py-4">
          <div class="flex flex-col space-y-4">
            <router-link to="/#benefits" class="text-base font-medium text-zinc-100 hover:text-emerald-500 transition-colors" @click="closeMenus">
              {{ t('nav.benefits') }}
            </router-link>
            
            <!-- Solutions Dropdown Mobile -->
            <div class="relative">
              <button 
                @click="isSolutionsOpen = !isSolutionsOpen"
                class="text-base font-medium text-zinc-100 hover:text-emerald-500 flex items-center"
              >
                {{ t('nav.solutions') }}
                <ChevronDown class="h-4 w-4 ml-1 transition-transform" :class="{ 'rotate-180': isSolutionsOpen }" />
              </button>
              <div 
                v-if="isSolutionsOpen"
                class="absolute left-0 mt-2 w-48 rounded-md shadow-lg bg-zinc-900 ring-1 ring-black ring-opacity-5 transition-all duration-200 transform origin-top-left"
              >
                <div class="py-1">
                  <router-link v-for="solution in solutionLinks" 
                     :key="solution.href" 
                     :to="`/${solution.href}`"
                     class="block px-4 py-2 text-sm text-zinc-400 hover:text-zinc-100 hover:bg-zinc-800"
                     @click="closeMenus"
                  >
                    {{ solution.text }}
                  </router-link>
                </div>
              </div>
            </div>

            <router-link to="/#process" class="text-base font-medium text-zinc-100 hover:text-emerald-500 transition-colors" @click="closeMenus">
              {{ t('nav.process') }}
            </router-link>
            <router-link to="/#use-cases" class="text-base font-medium text-zinc-100 hover:text-emerald-500 transition-colors" @click="closeMenus">
              {{ t('nav.useCases') }}
            </router-link>
            <router-link to="/contact" class="text-base font-medium text-zinc-100 hover:text-emerald-500 transition-colors" @click="closeMenus">
              {{ t('nav.contact') }}
            </router-link>
            <LanguageSwitcher />
            <router-link to="/contact" class="w-full px-4 py-2 bg-emerald-500 hover:bg-emerald-600 text-zinc-950 rounded-md transition-colors" @click="closeMenus">
              {{ t('nav.getPrototype') }}
            </router-link>
          </div>
        </div>
      </Transition>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Bot, Menu, X, MapPin, ChevronDown } from 'lucide-vue-next'
import LanguageSwitcher from './LanguageSwitcher.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const isMobileMenuOpen = ref(false)
const isSolutionsOpen = ref(false)

const closeMenus = () => {
  isMobileMenuOpen.value = false
  isSolutionsOpen.value = false
}

const solutionLinks = computed(() => [
  { href: '/#chatbots', text: t('solutions.links.chatbots') },
  { href: '/#voice-agents', text: t('solutions.links.voiceAgents') },
  { href: '/#automations', text: t('solutions.links.processAutomation') },
  { href: '/#marketing', text: t('solutions.links.marketingAutomation') },
  { href: '/#lead-generation', text: t('solutions.links.leadGeneration') }
])
</script>